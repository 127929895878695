import Vue from 'vue';
import App from './App';
import Router from './router';
import KeenVue from 'keen-vue';

// Styling (Custom)
import '@/assets/sass/newcold.scss';

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import auth from '@/libs/auth';
import eventhub from '@/libs/eventhub';
import ws from '@/libs/ws';
import user from '@/libs/user';

Vue.prototype.$auth = auth;
Vue.prototype.$eventhub = eventhub;
Vue.prototype.$ws = ws;
Vue.prototype.$user = user;

library.add(fas, fab, fad);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

Vue.use(KeenVue);

// Router scope
Router.beforeEach(async (to, from, next) => {
  if(to.meta.scope) {
    if(to.meta.scope === "authenticated") {

      let authenticated = false;
      try {
        authenticated = await auth.isAuthenticated();
      } catch(e) {
        await auth.logout();
        authenticated = false;
      }

      if(authenticated === true) next();
      else next("/signin");

    } else if(to.meta.scope === "notAuthenticated") {

      let authenticated = false;
      try {
        authenticated = await auth.isAuthenticated();
      } catch(e) {
        await auth.logout();
        authenticated = false;
      }

      if(authenticated === false) next();
      else next("/");

    } else if(to.meta.scope === "public") next();
    else next({name: "PageNotFound"});
  } else next();
})

const init = async () => {
  ws.initConnection();

  try {
    await auth.isAuthenticated();
  } catch(e) {}

  new Vue({
    el: "#app",
    router: Router,
    components: { App },
    template: '<App/>'
  })
}

Vue.config.productionTip = false;

init();
