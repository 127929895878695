<template>
  <KHeader>

    <KHeaderMenu>

    </KHeaderMenu>

    <KHeaderTopbar>

      <KHeaderTopbarItem variant="user" dropdown-small>
        <template v-slot:wrapper>
          <div class="kt-header__topbar-user kt-rounded-">
            <span class="kt-header__topbar-username kt-hidden-mobile">{{ $auth.user.name }}</span>
            <span class="kt-badge kt-badge--username kt-badge--lg kt-badge--primary kt-badge--bold">{{ $user.initials() }}</span>
          </div>
        </template>

        <template v-slot:default>
          <ul class="kt-nav kt-margin-b-10">
            <li class="kt-nav__custom">
              <KButton variant="primary" @click.prevent.native="logout" class="btn-upper btn-block btn-sm btn-bold" elevate>Sign Out</KButton>
            </li>
          </ul>

        </template>
      </KHeaderTopbarItem>

    </KHeaderTopbar>

  </KHeader>
</template>

<script>
export default {
  methods: {
    async logout() {
      await this.$auth.logout();
      this.$router.push("/signin");
    }
  }
}
</script>
