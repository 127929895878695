<template v-if="mounted === true">
  <KRoot v-if="isStandalonePage === false" aside header>

    <KHeaderMobile href="#" :logo="require('@/assets/images/logo/newcold.white.full.svg')"></KHeaderMobile>

    <KPage>

      <partialAside />

      <KWrapper>

        <partialHeader />

        <router-view :key="$route.fullPath" />

      </KWrapper>

    </KPage>
  </KRoot>

  <router-view v-else :key="$route.fullPath" />
</template>

<script>
import partialAside from "./components/partials/aside/"
import partialHeader from "./components/partials/header/"

export default {
  data() {
    return {
      mounted: false
    }
  },
  components: {
    partialAside,
    partialHeader
  },
  computed: {
    isStandalonePage() {
      if(this.$route.fullPath === "/") return true; // The start page is a standalone page, because it's redirecting based on the authentication flow
      if(typeof this.$route.meta.standalone === "boolean") return this.$route.meta.standalone;
      return false;
    }
  },
  mounted() {
    this.mounted = true;
  }
}
</script>

<style>
.kt-header-mobile .kt-header-mobile__logo img {
  width: auto;
  height: 40px;
}

/* Disable KHeaderMenu, because we don't have a header menu */
.kt-header-mobile .kt-header-mobile__toolbar #kt_header_mobile_toggler {
  display:none !important;
}
</style>
