<template>
  <div v-if="site !== null">

    <KSubheader
      :title="site.city"
      :breadcrumbs="[{
        page: 'Sites',
        route: '/sites'
      }, {
        page: site.city,
        route: null
      }]"
    >

    </KSubheader>

    <KContainer>
      <template v-if="site.disabled === true">
        <KAlert variant="light" :icon="['fad', 'exclamation-triangle']" elevate>
          This site is disabled.<br />
          Please <router-link tag="a" to="/sites">go back</router-link> to the site overview and select an enabled site.
        </KAlert>
      </template>

      <div class="row d-none d-lg-block">
        <div class="col-12">
          <siteBlock
            :site="site"
          />
        </div>
      </div>

      <template v-if="site.disabled === false">
        <div class="row">
          <div class="col-12">

            <KPortlet v-if="site.categories.length > 0">
              <KPortletHead>Energy consumption input</KPortletHead>
              <KPortletBody>

                <div class="row">
                  <div class="offset-sm-0 col-sm-12 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4">
                    <form class="kt-form" @submit.prevent="submitMeters">

                      <!-- Category selector -->
                      <div class="form-group">
            						<label for="manualInput.category">To get started, please choose your hall.</label>
            						<select class="form-control" id="manualInput.category" :value="manualInput.category" v-on:change="changeCategory($event.target.value)">
                          <option :value="null" disabled>Choose your hall</option>
            							<option v-for="category, index in site.categories" :value="category.ID">{{ category.name }}</option>
            						</select>
            					</div>

                      <!-- Inputs when category is selected -->
                      <template v-if="manualInput.category !== null">

                        <KSeparator large />

                        <!-- Meter inputs -->
                        <div v-for="meter, index in manualInput.manual_meters" :key="`meter.${meter.ID}`" class="form-group">
                          <label>{{ meter.name }}</label>
                          <div class="input-group">
              							<input type="text" class="form-control" step="0.1" v-on:focusout="meterFocusOut(meter.ID, $event)">
              							<div class="input-group-append">
                              <span class="input-group-text">kWh</span>
                            </div>
              						</div>
                          <span class="form-text text-muted">
                            <span v-if="meter.last_value !== null">Last input: {{ (meter.last_value.value / 1000) }} kWh ({{ moment(meter.last_value.created).tz(site.timezone).format("LL [at] HH:mm") }})</span>
                          </span>
                        </div>

                        <KSeparator large />

                        <!-- Form actions -->
              					<div class="kt-form__actions">
              						<KButton type="submit" variant="brand">Submit</KButton>
              						<KButton type="button" @click.native.prevent="changeCategory(null)" variant="secondary">Cancel</KButton>
                        </div>

                      </template>

                    </form>
                  </div>
                </div>

                <KModal ref="energyFormModal" size="lg" title="Energy consumption input">
                  <KAlert :variant="manualInput.form.modal.alert.variant" :icon="manualInput.form.modal.alert.icon" class="mb-0">{{ manualInput.form.modal.alert.text }}</KAlert>
                </KModal>

              </KPortletBody>
            </KPortlet>

            <KAlert v-else variant="light" :icon="['fad', 'info-circle']" elevate>
              This site doesn't have meters that require manual input
            </KAlert>

          </div>
        </div>
      </template>
    </KContainer>

  </div>
</template>

<script>
import moment from 'moment-timezone';
import siteBlock from '../site/block'

export default {
  data() {
    return {
      site: null,

      manualInput: {
        category: null,
        meters: [],

        manual_meters: [],

        form: {
          modal: {
            alert: {
              text: null,
              icon: null,
              variant: null
            }
          }
        }
      }
    }
  },
  components: {
    siteBlock
  },
  methods: {
    moment(...args) {
      return moment(...args);
    },

    async getSite() {
      // Get site
      let site = await this.$ws.get('v1', `/sites/${this.$route.params.guid}`);

      // Get categories
      site.categories = await this.$ws.get('v1', `/sites/${this.$route.params.guid}/categories/manual`);

      // Set site
      this.$set(this, 'site', site);
      return this.site;
    },

    async changeCategory(meterID) {
      this.$set(this.manualInput, 'manual_meters', []);
      this.$set(this.manualInput, "category", null);
      this.$set(this.manualInput, "meters", []);

      if(meterID !== null) {
        let category = this.site.categories.find(c => c.ID === meterID);
        if(typeof category !== typeof undefined && category !== null) {

          let meters = await this.$ws.get('v1', `/site_categories/${category.ID}/meters`);
          this.$set(this.manualInput, 'manual_meters', meters);

          this.$set(this.manualInput, "category", category.ID);
          this.$set(this.manualInput, "meters", []);
        }
      }
    },

    deleteMeterInput(meterID) {
      let mMeterIndex = this.manualInput.meters.findIndex(m => m.meter === meterID);
      if(typeof mMeterIndex !== typeof undefined && mMeterIndex !== -1) this.manualInput.meters.splice(mMeterIndex, 1);
      return true;
    },

    meterFocusOut(meterID, event) {
      let value = event.target.value;
      if(typeof value === "string" && value.length > 0) {

        value = value.replace(",", ".")

        value = Number(value);
        if(isNaN(value) === false) {
          let meter = this.manualInput.manual_meters.find(m => m.ID === meterID);
          if(typeof meter !== typeof undefined && meter !== null) {

            // Set value from kWh to Wh
            // This is better than using floats, which are not precise
            let valueWh = (value * 1000);

            // Check if meter is already inserted
            let mMeterIndex = this.manualInput.meters.findIndex(m => m.meter === meter.ID);
            if(typeof mMeterIndex !== typeof undefined && mMeterIndex !== -1) this.manualInput.meters[mMeterIndex].value = valueWh;
            else this.manualInput.meters.push({
              meter: meter.ID,
              value: valueWh
            })

            event.target.value = value;

          }
        } else {
          this.deleteMeterInput(meterID);
          event.target.value = null;
        }

      } else {
        this.deleteMeterInput(meterID);
        event.target.value = null;
      }
    },

    async submitMeters() {
      if(this.manualInput.category !== null) {

        let response = null;
        try {
          response = await this.$ws.post('v1', `/sites/${this.$route.params.guid}/${this.manualInput.category}/meter_input`, {
            body: this.manualInput.meters
          })
        } catch(e) {

          this.manualInput.form.modal.alert.variant = "danger";
          this.manualInput.form.modal.alert.icon = "times";
          this.manualInput.form.modal.alert.text = e;
          this.$refs["energyFormModal"].show();

          return e;
        }

        // Configure modal
        this.manualInput.form.modal.alert.variant = "success";
        this.manualInput.form.modal.alert.icon = "check";
        this.manualInput.form.modal.alert.text = "Your input(s) were saved successfully.";
        this.$refs["energyFormModal"].show();

        // Refresh site and set category to null again
        this.changeCategory(null);
        await this.getSite();

        return response;

      } else return new Error("CategoryUndefined");
    }
  },
  async mounted() {
    try {
      await this.getSite();
      // await this.getSiteCategories();
    } catch(e) {
      console.error("Site not found", e)
    }
  }
}
</script>
