import eventhub from '@/libs/eventhub';
import ws from '@/libs/ws';
import user from "@/libs/user";

export default class auth {
  static authenticate () {
    eventhub.emit('auth:authenticated', true);
  }

  static async runAuthenticatedFunctions () {
    await auth.setCurrentUser();
  }

  static async isAuthenticated() {
    if(auth.user) return true;

    const accessToken = auth.getAccessToken();
    if (!accessToken) return false;

    auth.accessToken = accessToken;
    const currentUser = await auth.getCurrentUser();
    if(currentUser) {
      auth.authenticate();
      return true;
    }

    return false;
  }

  static async setCurrentUser() {
    auth.user = await ws.get('v1', '/users/me', {
      headers: {
        ["x-session-token"]: auth.accessToken
      }
    });

    user.user = auth.user;
  }

  static getAccessToken () {
    return window.localStorage.getItem('accessToken');
  }

  static saveAccessToken(accessToken) {
    auth.accessToken = accessToken;
    window.localStorage.setItem('accessToken', accessToken);
  }

  static async getCurrentUser() {
    if (auth.accessToken && !auth.user) await auth.runAuthenticatedFunctions();

    return auth.user;
  }

  static async logout() {
    auth.user = undefined;
    user.user = undefined;
    window.localStorage.removeItem('accessToken');
    eventhub.emit('auth:authenticated', false);
  }
}
