import Vue from 'vue'
import Router from 'vue-router'

// Sites
import sitesOverview from "@/components/sites/overview"
import sitesView from "@/components/sites/view"

// Auth
import authSignIn from "@/components/auth/signIn"

// Errors
import pageNotFound from "@/components/errors/pageNotFound"

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [{
    path: "/",
    redirect: '/sites'
  },
  {
    path: "/sites",
    name: "SitesOverview",
    component: sitesOverview,
    meta: {
      scope: "authenticated"
    }
  },
  {
    path: "/sites/:guid",
    name: "SitesView",
    component: sitesView,
    meta: {
      scope: "authenticated"
    }
  },

  // Auth
  {
    path: "/signin",
    name: "SignIn",
    component: authSignIn,
    meta: {
      scope: "notAuthenticated",
      standalone: true
    }
  },

  // 404 Not Found
  {
    path: "*",
    name: "PageNotFound",
    component: pageNotFound,
    meta: {
      scope: "public"
    }
  }]
})
