<template>
  <KPortlet class="kt-portlet--height-fluid site">
    <KPortletHead>
      <template v-slot:default>
        <span style="display:block; font-size:20px;">{{ site.name }}</span>
        <span style="display:block; font-size:12px; font-weight:400;">{{ meta.country }}</span>
      </template>
      <template v-slot:toolbar>
        <KButton tag="span" variant="label-brand" class="btn-sm" :icon="['fad', 'clock']">{{ meta.localTime.format("HH:mm") }} (GMT {{ meta.localTime.format("Z") }})</KButton>
      </template>
    </KPortletHead>
    <KPortletBody>
      <div :id="`map_${site.ID}`" style="height:200px;"></div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
import moment from 'moment-timezone';
import config from '@/config'

import jvms from 'jvectormap-next/jquery-jvectormap.js'

export default {
  props: {
    site: Object
  },
  data() {
    return {
      meta: {
        country: null,

        localTime: moment(),
        _localTimeInterval: null
      },

      jVectorMap: {
        map: "world_mill",
        element: null,
        hidden: false
      }
    }
  },
  mounted() {

    let countryDetails = config.countries.find(c => c.code === this.site.country_code);
    if(typeof countryDetails === "object" && countryDetails !== null) {
      this.jVectorMap.map = countryDetails.jvectormap;
      this.meta.country = countryDetails.name;
    }

    // Set the jVectorMap, if it can be shown
    if(this.isMapVisible() === true) this.setJVectorMap(this.jVectorMap.map);
    else this.jVectorMap.hidden = true;

    // Update localTime every second with an interval
    this.meta.localTime = moment().tz(this.site.timezone);
    this.meta._localTimeInterval = setInterval(() => this.meta.localTime = moment().tz(this.site.timezone), 1000);

    // Add event listener on window resize
    window.addEventListener("resize", this.onWindowResize)
  },
  beforeDestroy() {
    // Destroy the _localTimeInterval
    if(this.jVectorMap.hidden === false) this.jVectorMap.element.vectorMap('get', 'mapObject').remove();
    window.removeEventListener("resize", this.onWindowResize)
    clearInterval(this._localTimeInterval);
  },
  methods: {
    isMapVisible() {
      const element = document.getElementById(`map_${this.site.ID}`);
      return (element.offsetWidth > 0 && element.offsetHeight > 0);
    },

    onWindowResize() {
      if(this.jVectorMap.hidden === true && this.isMapVisible() === true) {
        this.setJVectorMap(this.jVectorMap.map);
        this.jVectorMap.hidden = false;
      } else if(this.jVectorMap.hidden === false && this.isMapVisible() === false) {
        this.jVectorMap.element.vectorMap('get', 'mapObject').remove();
        this.jVectorMap.hidden = true;
      }
    },

    setJVectorMap(map) {
      // Check if jQuery and jQuery VectorMap are included already
      // Checking it here, because it's a component
      if(typeof window.jQuery === typeof undefined) window.jQuery = require('jquery');
      if(typeof window.jQuery.fn.vectorMap === typeof undefined) window.jvm = require('jvectormap-next')(window.jQuery);

      // Set jVectorMap elements
      this.jVectorMap.element = window.jQuery(`#map_${this.site.ID}`);
      this.jVectorMap.element.empty();

      if(map !== null) {

        // Check if map is loaded. If not, load it.
        try {
          this.jVectorMap.element.vectorMap({map: map});
          this.jVectorMap.element.vectorMap('get', 'mapObject').remove();
          this.jVectorMap.element.empty();
        } catch(e) {
          require(`@/assets/js/jvectormap/${map}.js`);
        }

        this.jVectorMap.element.vectorMap({
          map: map,
          backgroundColor: null,
          zoomButtons: false,
          zoomOnScroll: false,
          markers: [
            {latLng: [this.site.latitude, this.site.longitude], name: "Site"}
          ],
          onRegionLabelShow: (event, label, code) => {
            return event.preventDefault();
          },
          onRegionTipShow: (event, label, code) => {
            return event.preventDefault();
          }
        })
      }
    }
  }
}
</script>

<style>
.jvectormap-tip {
  display: none;
}
</style>
