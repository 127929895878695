<template>
  <div>
    <h1>404 - Page not found </h1>
  </div>
</template>

<script>
export default {
  methods: {

  },
  mounted() {

  }
}
</script>
