import shortid from 'shortid';

import auth from '@/libs/auth';
import config from '@/config';

export default class ws {
  static uri () {
    if (!ws.hostname) {
      ws.hostname = `${config.websocket}`;
    }
    return ws.hostname;
  }

  static async initConnection () {
    if (ws.connection) {
      return;
    }

    const uri = ws.uri();
    ws.connection = Primus.connect(uri);
    ws.connection.on('data', ws.data);
  }

  static generateRequestId () {
    return shortid.generate();
  }

  static async data (message = {}) {
    if (message.requestId && ws.pendingRequests[message.requestId]) {
      const { resolve, reject } = ws.pendingRequests[message.requestId];
      if (message.code === 200) {
        resolve(message.data);
      } else {
        console.log(message);
        reject(new Error("API ERROR"));
      }
      return;
    }

    console.error('Websocket without requestId:', message);
  }

  static async write (payload) {
    await ws.initConnection();
    ws.connection.write(payload);
  }

  static async request (method = 'get', version = 'v1', endpoint = '/', options = {}) {
    const url = '/api/' + version + endpoint;
    if(!options.query) options.query = {};
    if(!options.headers) options.headers = {};

    if(auth.accessToken) options.headers["x-session-token"] = auth.accessToken;

    return new Promise(async (resolve, reject) => {
      const requestId = ws.generateRequestId();

      await ws.write({
        requestId: requestId,
        type: 'request',
        method: method,
        url: url,
        headers: options.headers,
        query: options.query,
        body: options.body
      });

      if (!ws.pendingRequests) {
        ws.pendingRequests = {};
      }
      ws.pendingRequests[requestId] = { resolve, reject };
    }).catch((err) => {
      console.log('Websocket request failed:', method, url, err, '\nbody:', options.body);
      return Promise.reject(err);
    });
  }

  static async get (version = 'v1', endpoint = '/', options = {}) {
    return ws.request('get', version, endpoint, options);
  }

  static async post (version = 'v1', endpoint = '/', options = {}) {
    return ws.request('post', version, endpoint, options);
  }

  static async put (version = 'v1', endpoint = '/', options = {}) {
    return ws.request('put', version, endpoint, options);
  }

  static async patch (version = 'v1', endpoint = '/', options = {}) {
    return ws.request('patch', version, endpoint, options);
  }

  static async delete (version = 'v1', endpoint = '/', options = {}) {
    return ws.request('delete', version, endpoint, options);
  }
}
