import merge from 'merge';

import baseConfig from './config/config.base.json';

let mergeConfig = {};
if(process.env.NODE_ENV === "development") mergeConfig = require("./config/config.dev.json");
else mergeConfig = require("./config/config.prod.json");

const config = merge(baseConfig, mergeConfig);

export default config;
