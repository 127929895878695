<template>
  <div>

    <KSubheader
      title="Sites"
      :breadcrumbs="[{
        page: 'Sites',
        route: null
      }]"
    >

    </KSubheader>

    <KContainer>
      <div class="row">

        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12" v-for="(site, index) in sites">
          <siteBlock
            :site="site"
            :disabled="site.disabled"
            @click.native.prevent="goToSite(site.ID)"
          />
        </div>
      </div>
    </KContainer>
  </div>
</template>

<script>
import siteBlock from '../site/block'

export default {
  data() {
    return {
      sites: []
    }
  },
  components: {
    siteBlock
  },
  methods: {
    async getSites() {
      this.sites = await this.$ws.get("v1", "/sites");
      return this.sites;
    },
    goToSite(ID) {
      this.$router.push(`/sites/${ID}`)
    }
  },
  async mounted() {
    await this.getSites();
  }
}
</script>

<style scoped>
.kt-portlet.site {
  cursor: pointer;
}
</style>
