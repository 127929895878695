<template>
  <div class="flex">
    <KLogin>
      <KLoginHeader>
        <div class="kt-login-v2__logo">
          <a href="#">
            <img src="@/assets/images/logo/newcold.full.svg" width="200" alt="" />
          </a>
        </div>

        <div class="kt-login-v2__signup">
          <span>Manual Input Energy Consumption</span>
        </div>
      </KLoginHeader>

      <KLoginBody :image="require('@/assets/images/logo/newcold.svg')">
        <div class="kt-login-v2__title">
          <h3>Sign in</h3>
        </div>

        <div class="kt-login-v2__form kt-form" style="padding-top:20px;">
          <div class="text-center">
            <KButton tag="a" :href="`${config.api}/v1/auth/signin/protocol/AAD/${callbackURI}`" variant="primary" elevate-air class="btn-lg" :icon="['fab', 'microsoft']">Microsoft</KButton>
          </div>
        </div>
      </KLoginBody>

      <KLoginFooter>
        <div class="kt-login-v2__info">
          <a href="#" class="kt-link">&copy; {{ new Date().getFullYear() }} VeDicium</a>
        </div>

        <div class="kt-login-v2__link">
          <a href="https://www.newcold.com/" target="_blank" class="kt-link kt-font-primary">Newcold</a>
          <a href="https://www.newcold.com/privacy-statement/" target="_blank" class="kt-link kt-font-primary">Privacy</a>
          <a href="https://www.newcold.com/contact/" target="_blank" class="kt-link kt-font-primary">Contact</a>
        </div>
      </KLoginFooter>
    </KLogin>
  </div>
</template>

<script>
import "keen-vue/src/assets/sass/pages/login/login-v2.scss";

import config from "@/config";

export default {
  data() {
    return {
      config: config
    }
  },
  async mounted() {
    if(typeof window.location.hash === "string" && window.location.hash.length > 0) {
      let accessToken = window.location.hash.substr(1);

      // Remove hashtag from route
      this.$router.push(window.location.pathname)

      // Set session token
      await this.setAccessToken(accessToken);

      // Go to root
      this.$router.push("/");
    }
  },
  computed: {
    callbackURI() {
      return encodeURIComponent(window.location.href);
    }
  },
  methods: {
    async setAccessToken(token) {

      let response = await this.$ws.get("v1", "/users/me", {
        headers: {
          ["x-session-token"]: token
        }
      })

      this.$auth.saveAccessToken(token);
      await this.$auth.runAuthenticatedFunctions();
      this.$auth.authenticate();
    }
  }
}
</script>

<style>
.kt-login-v2 .kt-login-v2__body .kt-login-v2__image {
  height:250px;
  margin-left: 4rem;
}

@media (max-width: 1024px) {
  .kt-login-v2 .kt-login-v2__body .kt-login-v2__image {
    margin-left: 0;
  }
}
</style>
